import localFont from 'next/font/local';

import styles from './Layout.module.scss';

export const Geologica = localFont({
  src: '../../../public/fonts/Geologica-Regular.ttf',
  variable: '--my-font',
  weight: '400',
});

type Props = {
  children?: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <div className={`${styles.layout} ${Geologica.className}`}>
      <main className={styles.main}>{children}</main>
    </div>
  );
};

export default Layout;
