/* eslint-disable @next/next/inline-script-id */
import '@/styles/global.css';

import type { AppProps } from 'next/app';
import Script from 'next/script';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import Layout from '@/components/Layout/Layout';

interface State {
  currency: string;
  companyToHighlight: string;
}

export const CurrencyContext = createContext<{
  state: State;
  setState: Dispatch<SetStateAction<State>>;
}>({
  state: {
    currency: 'usd',
    companyToHighlight: '',
  },
  setState: () => {},
});

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const [state, setState] = useState({
    currency: 'usd',
    companyToHighlight: '',
  });

  const contextValue = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );

  return (
    <CurrencyContext.Provider value={contextValue}>
      <Layout>
        <Component {...pageProps} />
      </Layout>
      <>
        <Script
          async
          src='https://www.googletagmanager.com/gtag/js?id=G-3290DD1H4E'
          strategy='afterInteractive'
        />
        <Script strategy='afterInteractive'>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-3290DD1H4E');
          `}
        </Script>
      </>
    </CurrencyContext.Provider>
  );
}

export default MyApp;
